export const API_URL = 'https://strapi.bycoders.com.br';
export const SITE_URL = window.location.origin;

/**
 * Retrieve the full URL of Strapi asset. Trying first the optional format.
 *
 * @param {Object} resource
 * @param {String} format optional format (large, medium, small, thumbnail)
 * @returns {String}
 */
export const assetUrl = (resource, format = null) => {
  const url = format && resource?.formats?.[format]?.url;
  return url ?? resource?.url;
};
